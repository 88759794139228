.logo {
  height: 150px;
}

.maintitle {
  font-size: x-large;
  font-style: italic;
  font-weight: bold;
  color: red;
  cursor: pointer;
  text-align: center;

}

.mainphoto {
  max-width: 550px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.sitetitle {
  font-size: 110px;
  font-style: normal;
  color: black;
  margin-top: 25px;
  margin-bottom: 50px;
}

.centerFlexBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
.container {
  display: flex;
}*/

footer{
  height: 50px;
}

.root{
width:100%
}

@media (max-width:650px)  {.sitetitle{font-size: 15vw!important}}