.story {
    font-style: normal;
    font-size: small;
    font-weight: bold;
    color: black;
    text-decoration: underline;
}

.feeds {
    margin-top: 50px;;
}

.borders {
    border-right: 1px solid #888;
}

.leagueIcon {
    height: 150px;
}

#sports{
    border-right: 1px solid #888;
}

#sports:last-child {
    border-right: none!important;
}